const api = `https://api.groupfi.ai`
const tokenAddress = '0x30d2422A2cD8471e19867Bc41E76AeF6e0044A30'
const get = async (url) => {
    const response = await fetch(`${api}/${url}`, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
    return response.json()
}

window.isSending = false
const showGuideWithChainId = async (type) => {
    // if (window.provider && window.provider.request) {
    // const chainId = await window.provider.request({ method: 'eth_chainId' })
    // if (chainId == 148) {
    showGuide(type)
    // }
    if (type == 4) {
        setTimeout(() => {
            closeGuide()
        }, 15000)
    }
    // }
}

// tanglepay,metamask,okx,
// 判断是否安装tp钱包
window.onload = async () => {
    const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms))
    const connectDom = document.getElementById('connect-con')
    window.hideConnect = () => {
        connectDom.classList.add('hidden')
    }
    window.showConnect = () => {
        connectDom.classList.remove('hidden')
    }
    const storageKey = 'groupfi-wallet-info'
    function getWalletInfoFromStorage() {
        const walletInfoStr = window.localStorage.getItem(storageKey)
        if (walletInfoStr) {
            return JSON.parse(walletInfoStr)
        }
        return null
    }
    function storeWalletInfo(walletInfo) {
        localStorage.setItem(storageKey, JSON.stringify(walletInfo))
    }
    function clearWalletInfo() {
        walletInfo = null
        localStorage.removeItem(storageKey)
    }
    let walletInfo = getWalletInfoFromStorage()
    if (!walletInfo?.walletType) {
        walletInfo = null
    }
    const setAddress = function (walletInfo) {
        const address = walletInfo?.address
        var dom0 = document.getElementById('connect-btn')
        var dom1 = document.getElementById('has-connect-btn')
        if (address) {
            if (!dom0.classList.contains('hidden')) {
                dom0.classList.add('hidden')
            }
            if (dom1.classList.contains('hidden')) {
                dom1.classList.remove('hidden')
            }
            dom1.querySelector('.address').innerText = address.replace(/(^.{4})(.+)(.{4}$)/, '$1...$3')
        } else {
            if (!dom1.classList.contains('hidden')) {
                dom1.classList.add('hidden')
            }
            if (dom0.classList.contains('hidden')) {
                dom0.classList.remove('hidden')
            }
        }
        if (walletInfo) {
            storeWalletInfo(walletInfo)
        } else {
            clearWalletInfo()
        }
    }
    const setWalletItemDisabled = (walletName) => {
        const dom = document.getElementById(walletName)
        const span = dom.querySelector('span')
        dom.classList.add('disabled')
        const str = span.innerText
        span.innerText = str + ' (not detected)'
    }
    /*-------------------------------*/
    // tanglepay
    // if (!window.iota?.isTanglePay) {
    //     setWalletItemDisabled('tanglepay')
    // }
    // metamask
    const MMSDK = new MetaMaskSDK.MetaMaskSDK({
        dappMetadata: {
            name: 'groupfi.ai',
            url: window.location.href
        },
        extensionOnly: true
        // Other options.
    })
    await sleep(0)
    if (!MMSDK.getProvider()) {
        setWalletItemDisabled('metamask')
    }
    // okx
    if (!window.okxwallet?.isOkxWallet && !window.okxwallet?.isOKExWallet) {
        setWalletItemDisabled('okx')
    }
    if (!window.lukso) {
        setWalletItemDisabled('universalprofile')
    }
    // coinbase
    // if (!window.CoinbaseWalletSDK) {
    //     setWalletItemDisabled('coinbase')
    // }
    //trustwallet
    if (!window.trustwallet) {
        setWalletItemDisabled('trust')
    }
    if (!window.phantom?.solana) {
        setWalletItemDisabled('phantom')
    }

    const onMetaMaskAccountsChanged = (accounts) => {
        const account = accounts?.[0]
        walletInfo = {
            ...walletInfo,
            address: account
        }
        setAddress(walletInfo)

        if (account) {
            ChatboxSDK?.processAccount({
                account: account
            })
            closeGuide()
            checkAddressGetStatus().then((bool) => {
                bool && showGuideWithChainId(2)
            })
        } else {
            loadChatboxBrowser()
        }

        // if (window.provider && account) {
        //     loadChatbox(window.provider)
        //     checkAddressGetStatus().then((bool) => {
        //         bool && showGuideWithChainId(2)
        //     })
        // } else {
        //     loadChatboxBrowser()
        // }
        window.isSending = false
    }

    window.removeChatbox = function () {
        try {
            if (window.provider?.removeAllListeners) {
                window.provider.removeAllListeners()
            }
            if (window.provider?.off) {
                window.provider.off('accountsChanged', onMetaMaskAccountsChanged)
            }
            if (window.phantom?.solana) {
                window.phantom?.solana.removeListener('accountChanged', PhantomOnSolanaAccountsChanged)
            }
            setAddress(null)
            closeGuide()
            loadChatboxBrowser()
        } catch (error) {
            console.error('Error removing iframe:', error)
        } finally {
            // ChatboxSDK.loadChatbox()
            setAddress(null)
        }
    }

    window.openChatBox = function () {
        const dom = document.getElementById('groupfi_btn')
        if (dom) {
            if (!window.isSending) {
                if (!walletInfo?.address) {
                    showGuide(1)
                } else {
                    checkAddressGetStatus().then((bool) => {
                        bool && showGuideWithChainId(2)
                    })
                }
            }
            let openData = localStorage.getItem('trollbox.preference')
            try {
                openData = JSON.parse(openData)
            } catch (error) {
                openData = {}
            }
            if (openData?.isOpen) {
                return
            }
            var event = new MouseEvent('click', {
                view: window,
                bubbles: true,
                cancelable: true
            })
            dom.dispatchEvent(event)
        } else {
            showConnect()
        }
    }

    const loadChatboxBrowser = () => {
        if (isChatboxReady) {
            ChatboxSDK.processWallet({
                isWalletConnected: false
            })
        } else {
            ChatboxSDK.loadChatbox({
                isWalletConnected: false
            })
        }
        // ChatboxSDK.removeChatbox()
    }

    window.isChatboxReady = false

    const loadChatbox = async (provider, account) => {
        window.provider = provider

        if (isChatboxReady) {
            ChatboxSDK.processWallet({
                isWalletConnected: true,
                provider: provider
            })
            ChatboxSDK.processAccount({
                account: account
            })
        } else {
            ChatboxSDK.removeChatbox()
            ChatboxSDK.loadChatbox({
                isWalletConnected: true,
                provider: provider
                // theme: 'dark'
            })
        }
        // ChatboxSDK.loadChatbox({ walletType: 'metamask' })
        // ChatboxSDK.setWalletProvider(provider)
        // ChatboxSDK.request({
        //     method: 'setForMeGroups',
        //     params: {
        //         includes: [
        //             {
        //                 groupId: 'groupfiAnnouncement25f8059238b37fc4175a6581ea3f962059e4582ae9779fae8782551b314e228d'
        //             },
        //             {
        //                 groupId: 'groupfiEtherVisions1b51a2178f2fd6a8c436b924bec6a49d3b83e06f165303dff4604bed1fcc423e'
        //             },
        //             {
        //                 groupId: 'groupfiTOKENac3fc59f9142e741a1ae2f878187edf55d6ee7b2979e2d130375c589ca6488b8'
        //             }
        //         ],
        //         announcement: [
        //             {
        //                 groupId: 'groupfiAnnouncement25f8059238b37fc4175a6581ea3f962059e4582ae9779fae8782551b314e228d'
        //             }
        //         ]
        //     }
        // })
    }

    window.handleFaucet = async () => {
        if (window.provider && walletInfo?.address) {
            // closeGuide()
            showGuideWithChainId(3)
            window.isSending = true
            // const chainId = await window.provider.request({ method: 'eth_chainId' })
            const chainId = 148
            // if (chainId == 148) {
            await get(`faucet?chainid=${parseInt(chainId)}&token=${tokenAddress}&to=${walletInfo?.address}&amount=14000000`)
            loopCheckAddress()
            // }
        }
    }

    window.timeHandler = null
    window.timeNum = 0
    const initTimerHandler = () => {
        window.timeNum = 0
        clearInterval(window.timeHandler)
        window.timeHandler = null
        window.tokenContract = null
    }
    const loopCheckAddress = () => {
        if (window.timeHandler) {
            clearInterval(window.timeHandler)
        }
        window.timeHandler = setInterval(async () => {
            window.timeNum++
            const bool = await checkAddressGetStatus()
            if (!bool) {
                showGuideWithChainId(4)
                initTimerHandler()
                ChatboxSDK.request({
                    method: 'addAccountToGroupByGroupId',
                    params: {
                        groupId: `groupfiGTESTcrab08181a9bbb45f85ce1399009e9bb0c9ad40d965cadd6db33b5b52e53d297998a`
                    }
                })
            }
            if (window.timeNum > 30) {
                initTimerHandler()
            }
        }, 2000)
    }

    const checkAddressGetStatus = async (type = 2) => {
        if (window.provider && walletInfo?.address) {
            const chainId = await window.provider.request({ method: 'eth_chainId' })
            // if (chainId == 148) {
            // if (!window.tokenContract) {
            // const web3 = new window.Web3(new window.Web3.providers.HttpProvider('https://explorer.evm.shimmer.network'))
            // const web3 = new window.Web3(window.provider)
            // const tokenContract = new web3.eth.Contract(window.TokenERC20, tokenAddress)
            // window.tokenContract = tokenContract
            // }
            try {
                const url = `https://explorer.evm.shimmer.network/api/v2/addresses/${walletInfo?.address}/tokens?type=ERC-20`
                const response = await fetch(url)
                const data = await response.json()
                const tokenInfo = (data.items || []).find((e) => String(e.token?.address).toLocaleLowerCase() == tokenAddress.toLocaleLowerCase())
                // const balance = data.result
                const balance = tokenInfo.value

                console.log('🚀 ~ checkAddressGetStatus ~ balance:', balance, parseFloat(balance))
                if (parseFloat(balance) > 0) {
                    return false
                }
            } catch (error) {}
            return true
            // }
        }
        return true
    }

    function onSolanaAccountsChanged(provider) {
        return async (publicKey) => {
            if (publicKey) {
                const rawAccount = publicKey.toBase58()
                const account = rawAccount
                walletInfo.address = account
            } else {
                const resp = await provider.request({ method: 'connect' })
                const rawAccount = resp.publicKey.toString()
                const account = rawAccount
                walletInfo.address = account
            }
            setAddress(walletInfo)
            if (walletInfo.address) {
                ChatboxSDK?.processAccount({
                    account: walletInfo.address
                })
            } else {
                loadChatboxBrowser()
            }
        }
    }

    const getSolanaAccount = async (provider, walletType, isAuto) => {
        try {
            const resp = await provider.request({ method: 'connect' })
            const rawAccount = resp.publicKey.toString()

            if (!rawAccount) {
                throw new Error()
            }

            const account = rawAccount

            walletInfo = {
                address: account,
                walletType
            }
            setAddress(walletInfo)
            loadChatbox(provider, account)
            closeGuide()

            provider.on('accountChanged', PhantomOnSolanaAccountsChanged)
        } catch (err) {
            console.log(err)
        }
    }

    const PhantomOnSolanaAccountsChanged = onSolanaAccountsChanged(window.phantom?.solana)

    const getAccounts = async (provider, walletType, isAuto) => {
        if (!isAuto && walletType !== 'universalprofile') {
            await provider.request({
                method: 'wallet_requestPermissions',
                params: [
                    {
                        eth_accounts: {}
                    }
                ]
            })
        }
        const accounts = await provider.request({ method: 'eth_requestAccounts' }).catch(() => {
            throw 'ConnectFailed'
        })

        const rawAccount = accounts?.[0]
        if (!rawAccount) {
            loadChatboxBrowser()
            throw new Error()
        }
        const account = rawAccount.toLowerCase()
        // if (!/^0x/i.test(account)) {
        //     Toastify({
        //         text: 'Chain not supported',
        //         duration: 3000,
        //         position: 'center'
        //     }).showToast()
        //     throw new Error()
        // }
        walletInfo = {
            address: account,
            walletType
        }
        setAddress(walletInfo)
        loadChatbox(provider, account)
        provider.on('accountsChanged', onMetaMaskAccountsChanged)
        closeGuide()
        checkAddressGetStatus().then((bool) => {
            bool && showGuideWithChainId(2)
        })
    }

    window.handleConnect = async (type, isAuto = false) => {
        // window.removeChatbox()
        hideConnect()
        switch (type) {
            case 'metamask':
                getAccounts(MMSDK.getProvider(), type, isAuto)
                break
            case 'okx':
                getAccounts(window.okxwallet, type, isAuto)
                break
            case 'coinbase':
                const sdk = new CoinbaseWalletSDK({
                    appName: 'GroupFi.ai'
                })
                const provider = sdk.makeWeb3Provider()
                getAccounts(provider, type, isAuto)
                break
            case 'trust':
                getAccounts(window.trustwallet, type, isAuto)
                break
            case 'phantom':
                getSolanaAccount(window.phantom?.solana, type, isAuto)
                break
            case 'universalprofile':
                getAccounts(window.lukso, type, isAuto)
                break
            default:
                loadChatboxBrowser()
                break
        }
    }

    window.addEventListener('chatbox-ready', function () {
        isChatboxReady = true
        if (walletInfo?.address) {
            ChatboxSDK.processAccount({
                account: walletInfo.address
            })
        }
        ChatboxSDK.request({
            method: 'setGroups',
            params: {
                includes: [
                    {
                        groupId: 'groupfiadmin7ef7bd5f49843d162c869edc56c59ef73e123a872563cdca1f612267696ae3df'
                    },
                    {
                        groupId: 'groupfiIOTAEVMcrabe333d8e9cbb9795276533bfaca266b931770474b7f31e077c826ef2a925acf55'
                    },
                    {
                        groupId: 'groupfiShimmerEVMcrab3d9a333ddfedd754b4757234310fa9b436e011846f57c40b33c503ee8cc50284'
                    },
                    // {
                    //     groupId: 'groupfiPEPEcrab1dc67c5f01ced4af90bcd6180c9d99d123e916ba1d57275ae8402307d4dd2226'
                    // },
                    {
                        groupId: 'groupfiGTESTcrab08181a9bbb45f85ce1399009e9bb0c9ad40d965cadd6db33b5b52e53d297998a'
                    },
                    {
                        groupId: 'groupfiSOLANA1crab24c2212ac62d865f937921070d7002113aed01d3271759339639bc0a1ed2518a'
                    },
                    {
                        groupId: 'groupfiMangomarket3ba1189e8413950a81f81f1086c857de5b477a8f3e9c8b4648a914c61a6cc6c5'
                    },
                    {
                        groupId: 'groupfiLSP8NFTea29b066c6f3d391067fbb788a6d0b012f990bc49ab4242593994162268f9475'
                    },
                    {
                        groupId: 'groupfiLSP7Testcrab4ea99ce9a9ec12d4849420d663f6149a47903e7494605e34cd483573cf6818c6'
                    }
                    // {
                    //     groupId: 'groupfiGroupedApe3301f18083824e9c9a29093fa96de5ad18845a7d8b0c54b2237ea80aad98c9d4'
                    // }
                ],
                announcement: [
                    {
                        groupId: 'groupfiadmin7ef7bd5f49843d162c869edc56c59ef73e123a872563cdca1f612267696ae3df'
                    }
                ]
            }
        })
    })

    const script = document.createElement('script')
    // script.src = 'https://unpkg.com/groupfi-chatbox-sdk@0.0.5/dist/iife/index.js'
    script.src = 'https://prerelase.sdk.groupfi.ai/iife/index.js'
    script.async = true
    document.head.appendChild(script)
    script.onload = function () {
        handleConnect(walletInfo?.walletType, true)
    }
}
